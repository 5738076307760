
body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  text-align: center;
}


.fullview {
  width: calc(100% - var(--leftnav-width));
}

.shownav {
  position: relative;
  margin-left: var(--leftnav-width);
}

.fullview.hidenav {
  width: 100%;
}

.hidenav {
  padding: 0;
}

.reduced.shownav {
  width: calc(100% - var(--leftnav-width) - var(--rightpanel-width));
}

.reduced {
  position: relative;
  width: calc(100% - var(--rightpanel-width));
}





/* login */

.login-container {
  position: relative;
  max-width: 600px;
  margin: 10% auto;
  padding: 0px;
  display: flex;
  background: transparent;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.87);
  overflow: visible;
}
.login-container h1{
  color:var(--contrast-text-color);
}
.login-container  .MuiPaper-root h1{
  color:var(--text-color);
}
.backdrop {
  height: 100vh;
  width: 100vw;
  background: #e9e9e9;
  top: 0;
  position: absolute;
}

.videobg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  z-index: 0;
}

.bg {
  display: flex;
  min-width: 300px;
  align-items: center;
  justify-content: center;
}

.load-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  z-index: 100;
}

/* nav */
/*nav style*/
.nav-item label{ font-size:70%;  display:bxlock;   /* determine whether logo breaks line */ }
  /* determine whether logo breaks line */ 
.nav-item label{ display:inline; font-size:70%; line-height:25px; vertical-align: top; padding:10px 5px; }
:root{
  --leftnav-width:195px;
  --rightpanel-width:200px;
}


.left-nav-bar.hidden {
  transform: translateX(calc(-100% + 40px));
}
.left-nav-bar.hidden label{ display:none; }
.left-nav-bar.hidden { text-align:right; }
.left-nav-bar {
  width: var(--leftnav-width); 
  min-height: 100vh;
  height: 100%;
  background: linear-gradient(var(--secondary-color) 40%, var(--primary-color) 60%);
  background-size: 400% 200%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 36;
  transition: transform 0.3s ease;
  animation: gradientAnimation 3s linear infinite;
  overflow: hidden;
}

.bar {
  position: absolute;
  right: 0;
  width: 2px;
  height: 100%;
  background: #666;
  z-index: 2;
  top: 0;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 400% 40%;
  }
  100% {
    background-position: 0% 20%;
  }
}


.nav-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin-bottom: 1px;
  cursor: pointer;
  padding: 15px 10px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  opacity: 0.9;
 /* text-align: left;
*/
}

.nav-item * {
  outline: 0 0 2px #000;
  transition: color 0.5s;
}


.nav-item.active * {
  color: var(--text-color);
  transition: color 0.5s;
}

.nav-item:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.nav-item.active {
  position: relative;
  background: #fff;
  box-shadow: none;
  border: 1px solid;
  border-right: none;
  z-index: 3;
  opacity: 1;
}

.placeholder-text {
  transform-origin: bottom left;
  position: absolute;
  border: none;
  bottom: 60px;
  left: 0px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #fff;
  transform: translateX(var(--leftnav-width)) rotate(-90deg);
  transition: transform 0.3s ease;
  z-index: 40;
}

.placeholder-text.visible {
  transform: translateX(calc(var(--leftnav-width) - 20px)) rotate(-90deg);
}

.placeholder-text.hidden {
  display: none;
}

.toggle-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 6px 15px;
  border: none;
  border-radius: 2px;
  background-color: var(--secondary-color);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 9999;
}

.toggle-button:hover {
  background-color: var(--button-hover-background);
}

.right-panel .MuiDrawer-paper {
  background: #fff;
  box-shadow: 0 0 8px #333;
}
