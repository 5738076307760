
/* root colors defined in style */
body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1{ color:var(--background-color); }
table {
  min-width: 600px;
}



th,
td {
  border: 1px solid #ddd;
}

tr:hover td {
  background: #fff;
}

thead th {
  background-color: #f2f2f2;
}

@media screen and (max-width: 440px) {
  .login-container {
    display: flex;
    flex-direction: column;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Styles for desktop and larger screens */
@media screen and (min-width: 1200px) {
  /* Add your CSS rules here */
}

/* Styles for tablet-sized screens */
@media screen and (min-width: 440px) and (max-width: 1199px) {
  /* Add your CSS rules here */
  .templatedetails {
    display: none;
  }
}

/* Styles for mobile and smaller screens */
@media screen and (max-width: 440px) {
  /* Add your CSS rules here */
  .config > div {
    max-width: 100%;
    min-width: 0;
    margin: 0;
    display: block;
  }
}